import type { BaseSyntheticEvent } from 'react'

type INTERACTION_TYPE = 'button' | 'dropdown' | 'link'

type EVENT =
  | 'success'
  | 'warning'
  | 'failure'
  | 'input_start'
  | 'input_submit'
  | 'toggle'
  | 'checkbox'
  | 'click_interaction'
  | 'visualization'
  | 'scroll_depth'

type GtagParams = {
  event: EVENT
  interaction_type?: INTERACTION_TYPE
  source_id?: string
  label?: string
  interaction_label?: string
  interaction_result?: string
}

export function gtag(params: GtagParams, e?: BaseSyntheticEvent) {
  if (!window?.gtag) return

  const customEvent =
    e ??
    ({
      target: { name: 'DEFAULT_NAME', id: 'DEFAULT_ID' },
    } as BaseSyntheticEvent)

  const { name, id } = customEvent.target
  const { event, ...bodyParams } = params

  window.gtag('event', event, {
    ...bodyParams,
    source_id: params?.source_id ?? name ?? id,
  })
}
